// Facilities.tsx

import * as React from 'react';
import { IContextInterface, IFacilityInterface } from '../../../@types/context';
import { AppContext } from '../../context/AppContext';
import Facility from '../Facility/Facility';
// import '../../../styles.css'

const Facilities = () => {
  const { sessionid, matches, error, listview } = React.useContext(AppContext) as IContextInterface;
  //const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);
  if (!listview) {
    return null;
  }

  if (!sessionid || sessionid.length == 0) {
    return (
      null
    );
  }

  // Render error
  let errorstring = null;
  if (error && error.length > 0) {
    if (error === 'resultsexceeded') {
      errorstring = 'Too many results. Narrow your search.';
    }
    else if (error === 'smallsearch') {
      errorstring = 'Enter at least 3 characters to search.'
    }
  }

  return (
    <section className="section-search-results">
    <div className="container-medium">
      <div className="padding-small">
        <div className="search-results-wrapper">
          <div className="section-divider-line"></div>
          <div className="number-of-results-wrapper">
            <div className="number-of-results">{matches.length}</div>
            <div className="results-label">results</div>
            { errorstring &&
              <div className="results-label">.&nbsp;{errorstring}</div>
            }
          </div>
    <>
      {matches.map((facility: IFacilityInterface) => (
        <Facility key={facility.id} facility={facility} />
      ))}
    </>

        </div>
      </div>
    </div>
    </section>

  );
};

export default Facilities;