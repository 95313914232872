// AppContext.js
import React, { createContext, useState, FC, ReactNode } from 'react';

import { IFacilityInterface, IContextInterface } from '../../@types/context';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

// nop versions of setting the context
function default0(session: string) {};
function default2(matches: IFacilityInterface[], error?: string, search?: string, searchState?: string) {};
function default1(search?: string, searchState?: string) {};
function default5(listview: boolean, mapview: boolean, welcome: boolean, search: string, searchState: string) {};


const defaultContext = {
  sessionid: '',
  search: '',
  searchState: '',
  searchCity: '',
  matches: [],
  error: '',
  listview: false,
  mapview: false,
  welcome: true,
  welcomeState: '',
  saveSession: default0,
  saveSearch: default1,
  saveWelcomeState: default0,
  saveMatches: default2,
  saveView: default5,
};

interface Props {
  children: ReactNode;
}

export const AppContext = createContext<IContextInterface>(defaultContext);

//const AppProvider: React.FC<React.ReactNode> = ({ children: {children: ReactNode} }) => {
const AppProvider: React.FC<Props> = ({ children }: Props) => {
  const [context, setContext] = React.useState<IContextInterface>();

  //React.useEffect(() => {
  //  //console.log('useEffect context', context);
  //})

  const saveSearch = async (newsearch?: string, newsearchState?: string) => {
    //console.log('saveSearch', newsearch, newsearchState);
    // console.log('saveSearch', search, context);
    // console.log('saveSearch', search, searchState);
    const newcontext: IContextInterface = {
      sessionid: context ? context.sessionid : '',
      search: (newsearch == null) ? search : newsearch,
      searchCity: '',
      searchState: (newsearchState == null) ? searchState : newsearchState,
      listview: context ? context.listview : false,
      mapview: context ? context.mapview : false,
      welcome: context ? context.welcome : true,
      welcomeState: context ? context.welcomeState : '',
      matches: context ? context.matches : [],
      error: context ? context.error : '',
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default2,
      saveView: context ? context.saveView : default5,
    };

    
    setContext(newcontext);
    //await delay(10);
    //console.log('new context', newcontext, context);
  }

  const saveWelcomeState = async (welcomeState: string) => {
    //console.log('saveWelcomeState', welcomeState);
    const newcontext: IContextInterface = {
      sessionid: context ? context.sessionid : '',
      search: context ? context.search : '',
      searchCity: '',
      searchState,
      listview: context ? context.listview : false,
      mapview: context ? context.mapview : false,
      welcome: context ? context.welcome : true,
      welcomeState,
      matches: context ? context.matches : [],
      error: context ? context.error : '',
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default2,
      saveView: context ? context.saveView : default5,
    };
    
    setContext(newcontext);
  }

  const saveSession = (sessionid: string) => {
    //console.log(`saveSession: ${context?.searchState}`);
    const newcontext: IContextInterface = {
      sessionid,
      search: context ? context.search : '',
      searchCity: context ? context.searchCity : '',
      searchState: context ? context.searchState : '',
      listview: context ? context.listview : false,
      mapview: context ? context.mapview : false,
      welcome: context ? context.welcome : true,
      welcomeState: context ? context.welcomeState : '',
      matches: context ? context.matches : [],
      error: context ? context.error : '',
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default2,
      saveView: context ? context.saveView : default5,
    };
    setContext(newcontext);
  }


  const saveMatches = (matches: IFacilityInterface[], error?: string, newsearch?: string, newsearchState?: string) => {
    //console.log(`saveMatches: ${context?.searchState}`, newsearch, newsearchState);
    const newcontext: IContextInterface = {
      sessionid: context ? context.sessionid : '',
      search: (newsearch == null) ? search : newsearch,
      searchCity: '',
      searchState: (newsearchState == null) ? searchState : newsearchState,
      listview: context ? context.listview : false,
      mapview: context ? context.mapview : false,
      welcome: context ? context.welcome : true,
      welcomeState: context ? context.welcomeState : '',
      matches,
      error: error ? error : '',
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default2,
      saveView: context ? context.saveView : default5,
    };
    setContext(newcontext);
  }

  const saveView = (listview: boolean, mapview: boolean, welcome: boolean, search: string, searchState: string) => {
    //console.log('saveView', search, searchState);
    const newcontext: IContextInterface = {
      sessionid: context ? context.sessionid : '',
      search,
      searchCity: context ? context.searchCity : '',
      searchState,
      listview,
      mapview,
      welcome,
      welcomeState,
      matches: context ? context.matches : [],
      error: context ? context.error : '',
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default2,
      saveView: context ? context.saveView : default5,
    };
    setContext(newcontext);    
  }

  const sessionid = context ? context.sessionid : '';
  const search = context ? context.search : '';
  const searchCity = context ? context.searchCity : '';
  const searchState = context ? context.searchState : '';
  const matches = context ? context.matches : [];
  const error = context ? context.error : '';
  const listview = context ? context.listview : false;
  const mapview = context ? context.mapview : false;
  const welcome =  context ? context.welcome : true;
  const welcomeState = context ? context.welcomeState : '';

  return <AppContext.Provider value={{ sessionid, search, searchCity, searchState, listview, mapview, welcome, welcomeState, matches, error, saveSession, saveSearch, saveWelcomeState, saveMatches, saveView }}>{children}</AppContext.Provider>;
};  
  
export default AppProvider;
