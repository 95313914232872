// FacilityDetails.tsx

import * as React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Link, useNavigate } from 'react-router-dom';

import { Header } from '../../branding/Header';
import { Footer } from '../../branding/Footer';
import { ShowFile } from '../../file/ShowFile/ShowFile';

import { IContextInterface, IFacilityInterface, IFileInterface } from '../../../@types/context';
import { AppContext } from '../../context/AppContext';
import Facility from '../Facility/Facility';

import ServerService from '../../../services/server.service';

import MissingPage from '../../../routes/missingpage';
// import '../../../styles.css'

type Props = {
  facilityid: string;
};

const FacilityDetails: React.FC<Props> = ({ facilityid }) => {
  const { matches, search, searchState } = React.useContext(AppContext) as IContextInterface;
 
  const navigate = useNavigate();

 //console.log('matches', facilityid, matches);

  const facility: IFacilityInterface | undefined = matches.find(x => x.id == facilityid);

  if (!facility) {
    return (
      <MissingPage />
    );
  }

  // console.log('Showing facility', facility);
  return (
    <div>
    <section className="section-details-hospital-info">
    <div className="container-small center-details-page">
      <div className="padding-small">
        <div className="details-hospital-info-wrapper">
          <a href="#" onClick={() => navigate(-1)} className="back-to-search-link-block w-inline-block"><img src='/images/ICON_back.svg' loading="lazy" alt="back button" className="icon_back"/>
            <div className="label-back-to-search">search</div>
          </a>
          <div className="name-hospital-seach-result details-hospital-name">{facility?.name}</div>
          <a href={facility.url} target="_blank" className="link-block-5 w-inline-block">
            <div className="hospital-url">{facility?.url}</div>
          </a>
          <div className="details-hospital-wrapper">
            <div id="w-node-_114a0f28-4e1e-1765-5c50-b6d979b05d96-8d4be3ed" className="details-address-phone-ccn-wrapper">
              <div className="grid-hospital-details">
              <Link id="w-node-b46f49af-6191-255e-db1e-8cdb0ea4a8b9-8d4be3ed" className="link-block-4 w-inline-block" to={'/map/' + facility.id}>
                  <div className="map-pin-blue-icon-wrapper"><img src={process.env.PUBLIC_URL + '/images/ICON_pin_blue.svg'} loading="lazy" id="w-node-db507dec-5110-0625-995f-c519fbfc8ad9-8d4be3ed" alt="map view icon" className="details-address-icon-link"/></div>
                  <div id="w-node-_4e8266c1-6898-cec1-579e-b783d40089d2-8d4be3ed" className="details-hospital-street-address">{facility?.address}</div>
                </Link>
                <a id="w-node-a3eac0d5-a762-3b16-4265-45a9441bbf19-8d4be3ed" href="#" className="details-address-link-wrapper w-inline-block">
                  <div className="details-hospital-city-state-zip">{facility?.city},&nbsp;{facility?.state}&nbsp;{facility?.zip}</div>
                </a>
                <a id="w-node-b21336b7-8f6f-1864-bc30-4228286cdd1d-8d4be3ed" href="#" className="details-hospital-phone-number-wrapper w-inline-block">
                  <div className="phone-icon-blue-wrapper"><img src={process.env.PUBLIC_URL + '/images/ICON_phone.svg'} loading="lazy" alt="Phone number icon" className="details-phone-icon-link mobile-icon-invoke-call"/></div>
                  <div className="details-hospital-phone-number">{facility?.phone}</div>
                </a>
                <div id="w-node-_7dd16fd3-3280-13fe-c21d-f4250d73c833-8d4be3ed" className="details-hospital-ccn-wrapper"><img src={process.env.PUBLIC_URL + '/images/ICON_pin_blue.svg'} loading="lazy" id="w-node-a81c3d76-b684-dc1d-ae2b-b62981d5e81d-8d4be3ed" alt="map view icon" className="details-address-icon-link hide-map-pin-blue"/>
                { facility?.ccn &&
                  <div className="details-hospital-ccn-label">CCN</div>
                }
                { facility?.ccn &&
                  <div className="details-hospital-ccn-number">{facility?.ccn}</div>
                }
                </div>
              </div>
            </div>
            <div id="w-node-_31d4f197-fc05-af3b-883d-723a5b83e473-8d4be3ed" className="details-map-thumbnail-wrapper w-inline-block">
            <Link id="w-node-_31d4f197-fc05-af3b-883d-723a5b83e473-8d4be3ed" className="details-map-thumbnail-wrapper w-inline-block" to={'/map/' + facility.id}>

            <MapContainer style={{width: '174px', height: '95px'}} scrollWheelZoom={false} zoomControl={false} dragging={false} boxZoom={false} touchZoom={false} center={[parseFloat(facility?.lat || '28'), parseFloat(facility?.long || '-82')]} zoom={13}>
          <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright%22%3EOpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
          <Marker position={[parseFloat(facility?.lat || '28'), parseFloat(facility?.long || '-82')]}>
          </Marker>
        </MapContainer>
        </Link>
        </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  { facility && facility.files && facility.files.length &&
  <section className="section-details-price-transparency-files">
    <div className="container-small center-details-page">
      <div className="padding-small">
        <div className="details-latest-price-transparency-files-wrapper">
          <div className="details-title-price-transparency-files">Latest Hospital Price Transparency Files</div>
          <div className="details-price-transparency-file-links-wrapper padding-small">
            { facility.files[0].pageurl &&
            <a href={facility.files[0].pageurl} target="_blank" className="details-price-transparency-page-link-wrapper w-inline-block">
              <div className="label-price-transparency-file">Price Transparency Page:</div>
              <div className="details-price-transparency-file-url">{facility.files[0].pageurl}</div>
            </a>
            }
            { facility.files[0].url && 
            <a href={facility.files[0].url} target="_blank" className="details-price-transparency-page-link-wrapper margin-top margin-small w-inline-block">
              <div className="label-price-transparency-file">Link to Price Transparency File:</div>
              <div className="details-price-transparency-file-url">{facility.files[0].url}</div>
            </a>
            }
          </div>
          <div className="details-files-divider-line"></div>
          <div className="details-stored-files-wrapper padding-xsmall">
            <div className="details-title-stored-files">Stored Files</div>
            <>
            {facility?.files.map((file: IFileInterface) => (
              <ShowFile key={file.fileid} file={file}/>
            ))}
            </>

          </div>
        </div>
      </div>
    </div>
  </section>
  }
  <section className="section-details-report-issue-form">
    <div className="container-small center-details-page">
      <div className="padding-xsmall">
        <div className="details-report-issue-form-wrapper">
          <a href="https://visiblecharges.atlassian.net/servicedesk/customer/portal/1" className="button-report-issue-form w-button">report an issue</a>
        </div>
      </div>
    </div>
  </section>
  </div>
  );
};


export default FacilityDetails;