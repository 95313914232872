// Facility.tsx

import * as React from 'react';
import { Link } from 'react-router-dom';
import { IFacilityInterface, IFileInterface } from '../../../@types/context';
import ServerService from '../../../services/server.service';

import { ShowFile } from '../../file/ShowFile/ShowFile';

type Props = {
  facility: IFacilityInterface;
};

const Facility: React.FC<Props> = ({facility }) => {

  React.useEffect(() => {
    // console.log('Facility useEffect', facility);
  })

  // Use the primary file. If there is a converted file, use it instead
  let file: IFileInterface | null = facility.files.length > 0 ? facility.files[0] : null;
  if (facility.files.length > 1) {
    if (facility.files[1].converted) {
      file = facility.files[1];
    }
  }

  const serverService = new ServerService();
  const fileid = file?.fileid;
  const onclick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const endpoint = `file/link/${fileid}`;
    const linkdata = await serverService.getLink(endpoint);
    const url = linkdata?.link?.url;
    if (url) {
      window.location = url;
    }
  };



  let filesize = '';
  if (file) {
    if (file.size > 0) {
      if (file.size > 1024*1024) {
        filesize = `${Math.round(file?.size / 1024 / 1024 * 10) / 10} MB`;
      }
      else if (file.size > 1024) {
        filesize = `${Math.round(file?.size / 1024 * 10) / 10} KB`;
      }
      else {
        filesize = `${file.size} B`;
      }
    }
  }


  let retrieved = '';
  if (file) {
    if (file.retrieved && file.retrieved.length > 0) {
      retrieved = new Date(file.retrieved).toISOString().substring(0,10);
    }
  }

  let filetype = '';
  let filesuffix = '';
  if (file) {
    filesuffix = file.filesuffix;

    filetype = file.filetype ? file.filetype : '';
    if (filetype == 'other') {
      filetype = 'original';
    }
    // console.log(filetype, filesuffix);
    if (filetype == 'unknown' && filesuffix.includes('zip')) {
      filetype = 'compressed';
    }

  }

  // Render when no machine readable files
  function NoFiles(props: any) {
    const file: IFileInterface = props.file;
    return (
      <div id="w-node-_395d2ad2-f1d9-1490-162f-a9b3134744be-b803862a" className="w-layout-grid no-machine-readable-file-wrapper">
        <div className="no-machine-readable-file-found-text">No Machine-Readable File Found</div>
      </div>
    );
  }

  // Render when there are files
  function FileDetails(props: any) {
    return (
      <ShowFile key={file?.fileid} file={file} />
    );
  }

  // Render address when no files
  function LocationNoFiles(props: any) {
    return (
      <div className="info-hospital-search-result-wrapper">
        <div id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b34-66daa052" className="w-layout-grid hospital-info-grid">
          <div id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b35-66daa052" className="address-hospital-search-result-wrap"><img src="images/ICON_map-pin-1.svg" loading="lazy" id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b36-66daa052" alt="Address icon" className="icon-search-result"/>
            <div className="address-hospital">{facility.address},&nbsp;{facility.city},&nbsp;{facility.state}&nbsp;{facility.zip}</div>
          </div>
          <div id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b39-66daa052" className="phone-website-ccn-search-result-wrap">
            <div className="phone-number-with-icon-search-result"><img src="images/ICON_phone.svg" loading="lazy" alt="Phone number icon" className="icon-search-result"/>
              <div className="phone-number">{facility.phone}</div>
            </div>
            <div className="website-with-icon-search-result"><img src="images/ICON_website.svg" loading="lazy" id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b3f-66daa052" alt="Website icon" className="icon-search-result icon-website"/>
              <a id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b40-66daa052" href="https://www.adventhealth.com/hospital/adventhealth-tampa" target="_blank" className="website-search-result">website</a>
            </div>
            { facility.ccn &&
            <div id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b42-66daa052" className="ccn-number-search-result">
              <div id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b43-66daa052" className="ccn-label">CCN</div>
              <div id="w-node-_2d27962f-7c6d-48b1-bf35-95d0b94e0b45-66daa052" className="ccn-number">{facility.ccn}</div>
            </div>
            }
          </div>
        </div>
        <div id="w-node-_109767a6-8f8a-b4cc-6208-035cc649a297-66daa052" className="w-layout-grid no-machine-readable-file-wrapper">
          <div className="no-machine-readable-file-found-text">No Machine-Readable File Found</div>
        </div>
        <Link id="w-node-_109767a6-8f8a-b4cc-6208-035cc649a2a8-66daa052" className="link-block details-link-wrapper" to={'/details/' + facility.id}>details</Link>
      </div>
    );
  }

  // Render address when there are files
  function LocationWithFiles(props: any) {
    return (
        <div className="info-hospital-search-result-wrapper">
          <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888cfb-66daa052" className="w-layout-grid hospital-info-grid">
            <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888cfc-66daa052" className="address-hospital-search-result-wrap"><img src="images/ICON_map-pin-1.svg" loading="lazy" id="w-node-fc1225e1-6f93-de75-1473-ff91af888cfd-66daa052" alt="Address icon" className="icon-search-result"/>
              <div className="address-hospital">{facility.address},&nbsp;{facility.city},&nbsp;{facility.state}&nbsp;{facility.zip}</div>
            </div>
            <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d00-66daa052" className="phone-website-ccn-search-result-wrap">
              <div className="phone-number-with-icon-search-result"><img src="/images/ICON_phone.svg" loading="lazy" alt="Phone number icon" className="icon-search-result"/>
                <div className="phone-number">{facility.phone}</div>
              </div>
              <div className="website-with-icon-search-result"><img src="/images/ICON_website.svg" loading="lazy" id="w-node-fc1225e1-6f93-de75-1473-ff91af888d05-66daa052" alt="Website icon" className="icon-search-result icon-website"/>
                <a id="w-node-fc1225e1-6f93-de75-1473-ff91af888d06-66daa052" href={facility.url} target="_blank" className="website-search-result">website</a>
              </div>
              { facility.ccn &&
              <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d08-66daa052" className="ccn-number-search-result">
                <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d09-66daa052" className="ccn-label">CCN</div>
                <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d0b-66daa052" className="ccn-number">{facility.ccn}</div>
              </div>
              }
            </div>
          </div>

          <FileDetails />

          <Link id="w-node-fc1225e1-6f93-de75-1473-ff91af888d1e-d5517b60" className="link-block details-link-wrapper" to={'/details/' + facility.id}>details</Link>
        </div>
  
    );
  }

  return (
    <div className="search-result-item-wrapper mobile-search-result-card-style">
      <div className="name-hospital-search-result-wrapper">
        <Link className="link-block w-inline-block" to={'/details/' + facility.id}>
          <div className="name-hospital-seach-result">{facility.name}</div>
        </Link>
      </div>
      {(retrieved.length > 0) ?  <LocationWithFiles /> : <LocationNoFiles /> }

      <div className="search-result-item-divider"></div>
    </div>
  );
};

export default Facility;