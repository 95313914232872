// ShowFile.tsx

import * as React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Link, useNavigate } from 'react-router-dom';

import { Header } from '../../branding/Header';
import { Footer } from '../../branding/Footer';

import { IContextInterface, IFacilityInterface, IFileInterface } from '../../../@types/context';
import { AppContext } from '../../context/AppContext';
//import Facility from '../Facility/Facility';

import ServerService from '../../../services/server.service';

import MissingPage from '../../../routes/missingpage';
import '../../../styles.css'


export const ShowFile = (props: any) => {
  const file: IFileInterface = props.file;

  const serverService = new ServerService();
  const fileid = file.fileid;
  const onclick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const endpoint = `file/link/${fileid}`;
    const linkdata = await serverService.getLink(endpoint);
    const url = linkdata?.link?.url;
    if (url) {
      window.location = url;
    }
  };



  let size = '';
  if (file.size > 0) {
    if (file.size > 1024*1024*1024) {
      size = `${Math.round(file.size / 1024 / 1024 /1024 * 10) / 10}GB`;
    }
    else if (file.size > 1024*1024) {
      size = `${Math.round(file.size / 1024 / 1024 * 10) / 10}MB`;
    }
    else if (file.size > 1024) {
      size = `${Math.round(file.size / 1024 * 10) / 10}KB`;
    }
    else {
      size = `${file.size}B`;
    }
  }

  let retrieved = '';
  if (file.retrieved && file.retrieved.length > 0) {
    retrieved = new Date(file.retrieved).toISOString().substring(0,10);
  }

  let filesuffix = file.filesuffix;

  let filetype = file.filetype ? file.filetype : '';
  if (filetype == 'other') {
    filetype = 'original';
  }
  if (filetype == 'unknown' && filesuffix.includes('zip')) {
    filetype = 'compressed';
  }
  if (filetype == 'unknown' && filesuffix.includes('pdf')) {
    filetype = 'spreadsheet';
  }
  if (filetype == 'unknown' && filesuffix.includes('txt')) {
    filetype = 'structured';
  }

  return (
    <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d0d-66daa052" className="w-layout-grid price-transparency-file-grid">
    <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d0e-66daa052" className="file-download-info-search-result">
      <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d0f-66daa052" className="file-type-wrapper">
        <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d10-66daa052" className="file-type-text">spreadsheet</div>
        <div className="file-type-suffix">{filesuffix}</div>
      </div>
      <a id="w-node-_1bda2d84-714b-6b54-ec6a-c61fea702d65-66daa052" onClick={onclick} href="#" className="link-block-3 w-inline-block"><img src="/images/ICON_download_medium.svg" loading="lazy" id="w-node-fc1225e1-6f93-de75-1473-ff91af888d14-66daa052" alt="download file icon" className="icon-download"/></a>
      <a id="w-node-_772d3570-0118-e217-2909-753d705dab64-66daa052" onClick={onclick} href="#" className="link-block w-inline-block">
        <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d15-66daa052" className="label-price-transparency-file">Price Transparency File</div>
      </a>
      <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d17-66daa052" className="as-of-date-wrapper">
        <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d18-66daa052" className="as-of-label">as of</div>
        <div className="date-as-of">{retrieved}</div>
      </div>
      <div id="w-node-fc1225e1-6f93-de75-1473-ff91af888d1c-66daa052" className="size-of-file">{size}</div>
    </div>
  </div>

  );

}
  