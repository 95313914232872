// root.tsx (Used to be App.tsx)

import React, {useEffect} from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import '../styles.css';

import FacilitySearch from '../components/facility/FacilitySearch/FacilitySearch';
import Facilities from '../components/facility/Facilities/Facilities';
import Mapview from '../components/Mapview/Mapview';
import Session from '../components/user/Session/Session';
import ServerService from '../services/server.service';

import { Header } from '../components/branding/Header';
import { Welcome } from '../components/branding/Welcome';
import { Footer } from '../components/branding/Footer';

export default function Root() {

  useEffect(() => {
    // Ping the server to get a sessionid and requestid
    const findServer = async () => {
      const serverService = new ServerService();
      const resp = await serverService.getResponse('check');
      //console.log('resp', resp);
    };
    // console.log('useEffect running for App');
    findServer();
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <div className="global-styles w-embed">
          <link href="/css/globalstyles.css" rel="stylesheet" type="text/css"/>
        </div>

        <Header/>
        <main className="main-wrapper">
          <div>
            <Session/>
            <Welcome/>
            <FacilitySearch tableid="123" />
            <Facilities />
            <Mapview />
          </div>
        </main>
      </div>

    </div>
  );
}