// missingpage.tsx

import { Link, useRouteError } from 'react-router-dom';

export default function MissingPage() {
  const error = useRouteError() as any; // This is actually of type unknown

  return (
    <div className="utility-page_component">
      <div className="utility-page_wrapper"><img src="https://d3e54v103j8qbb.cloudfront.net/static/page-not-found.211a85e40c.svg" alt="" className="utility-page_image"/>
        <h3>Page Not Found</h3>
        <div>The page you are looking for doesn&#x27;t exist or has been moved</div>
        <Link id="w-node-fc1225e1-6f93-de75-1473-ff91af888d1e-d5517b60" className="link-block details-link-wrapper" to={'/'}>Go Home</Link>
      </div>
    </div>
  );
}