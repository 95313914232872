// Mapview.tsx

import * as React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { LatLngBoundsLiteral } from 'leaflet';
import { Link } from 'react-router-dom';

import { IContextInterface, IFacilityInterface } from '../../@types/context';
import { AppContext } from '../context/AppContext';
import '../../styles.css'

let sequenceid: number = 1;

const Mapview: React.FC = () => {
  const { sessionid, matches, mapview } = React.useContext(AppContext) as IContextInterface;

  const matchesWithCoords = matches.filter(x => x.lat && x.lat.length > 0 && x.long && x.long.length > 0);

  // Only show if enabled
  if (!mapview) {
    return null;
  }

  const markers = [];

  let lat = 28.002;
  let lng = -82.57;

  let latmin: number = 0.;
  let latmax: number = 0.;
  let lngmin: number = 0.;
  let lngmax: number = 0.;

  let latsum = 0;
  let lngsum = 0;
  let npoints = 0;
  for (const facility of matchesWithCoords) {
    if (facility.lat && facility.long) {
      const latvalue = parseFloat(facility.lat);
      const lngvalue = parseFloat(facility.long);

      latsum += latvalue;
      lngsum += lngvalue;
      npoints++;

      if (latmin == 0. || latvalue < latmin) { latmin = latvalue;}
      if (latmax == 0. || latvalue > latmax) { latmax = latvalue;}
      if (lngmin == 0. || lngvalue < lngmin) { lngmin = lngvalue;}
      if (lngmax == 0. || lngvalue > lngmax) { lngmax = lngvalue;}


    }
  }
  let bounds = [[latmin, lngmax] as any, [latmax, lngmin] as any];
  if (npoints > 0) {
    lat = latsum / npoints;
    lng = lngsum / npoints;
  }
  //console.log('center', lat, lng);
  //console.log('bounds', bounds);

  function ChangeView({center, zoom, bounds }: any) {
    const map = useMap();
    map.setView(center, zoom);
    map.fitBounds(bounds);
    return null;
  }

  if (!sessionid || sessionid.length == 0) {
    return (
      null
    );
  }

  if (true) {
  return (
    <div>
    <section className="section-map">

    <div className="container-medium">
      <div className="padding-small">
        <div className="map-plus-pagenav-wrapper">
        <MapContainer style={{ maxHeight: '480px', maxWidth: '640px'}} center={{ lat: lat, lng: lng }} zoom={12}>
      <ChangeView center={{ lat: lat, lng: lng }} bounds={bounds} zoom={12} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright%22%3EOpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <>
      {matchesWithCoords.map((facility:IFacilityInterface) => (
        <Marker key={sequenceid++} 
          position={[parseFloat(facility.lat || '28'), parseFloat(facility.long || '-82')]}
        >
          <Popup>
            <Link to={'/details/' + facility.id}>{facility.name}</Link><br/>
            {facility.address}<br/>
            {facility.city}, {facility.state} {facility.zip}
          </Popup>
        </Marker>
      ))}
      </>

    </MapContainer>

        </div>
      </div>
    </div>
  </section>
</div>

  );
  }
};

export default Mapview;