// details.tsx
import * as React from 'react';
import { useLoaderData } from 'react-router-dom';
import { IContextInterface, IFacilityInterface } from '../@types/context';
import { AppContext } from '../components/context/AppContext';

import FacilityDetails from '../components/facility/FacilityDetails/FacilityDetails';
import { Header } from '../components/branding/Header';
import { Footer } from '../components/branding/Footer';

export async function loader({params}: any) {
  const facilityid = params.facility;
  // console.log('facilityid', facilityid);

  return { facilityid };
}

export default function Details() {
  const params = useLoaderData() as any;
  const facilityid = params.facilityid;

  const { search, searchState } = React.useContext(AppContext) as IContextInterface;
  // console.log('Details', search, searchState);

  //console.log('response', facilityid);
  return (
    <div>
    <Header/>
    <main className="main-wrapper">
      <FacilityDetails key={facilityid} facilityid={facilityid}/>
    </main>

    </div>
  );
}
