// Welcome.tsx
import React, { useState } from 'react';
import { IContextInterface, IFacilityInterface } from '../../@types/context';
import { AppContext } from '../context/AppContext';

import ClickableUSAMap from '../USAState/ClickableUSAMap';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export const Welcome = () => {
  const { sessionid, welcome, welcomeState, saveWelcomeState, saveView } = React.useContext(AppContext) as IContextInterface;

  const onclickState = async function(state: string) {
    saveView(true, false, false, '', state);
    //console.log('onclick', welcomeState, state)
  }

  if (!sessionid || !welcome) {
    return (
      null
    );
  }

  return (
    <div>
    <section className="section-title-publicly-availaible-ptf">
    <div className="container-medium">
      <div className="padding-small heading-title-top-padding">
        <div className="page-title-wrapper">
          <h2 className="heading heading-style-h2 mobile-header-title">Publicly Available Hospital Price Transparency Files</h2>
        </div>
      </div>
    </div>
  </section>
  <section className="section-map-selector-paragraphs">
    <div className="container-medium">
      <div className="padding-small moble-header-text">
        <div className="header-text-map-wrapper">
          <div className="header-text-wrapper">
            <p className="header-text header-text-first-paragraph header-text-first-paragraph-mobile">The federal hospital price transparency rule requires hospitals to publish their prices for all items and services online in a machine-readable standard charges file for all negotiated rates by payer and plan, including discounted cash prices.</p>
            <p className="header-text">
              <a href="https://www.patientrightsadvocate.org/" className="header-text-link">PatientRightsAdvocate.org</a>  aggregated publicly available price transparency data from 6,000 U.S. hospitals to empower all Americans with actual, upfront hospital prices. We are working on behalf of patients, employers, unions, and workers in the fight for systemwide healthcare price transparency to reduce healthcare costs through a functional, competitive market.
            </p>
            <p className="header-text" style={{fontSize: '70%'}}>
            Because of the scope and constantly changing nature of hospital prices, PRA makes no warranty, and expressly disclaims all warranties, as to the accuracy or completeness of the files and other information provided. Price and other information should always be verified directly with hospitals and service providers. All use of this site and database is governed by our <a href="#">Terms of Service</a>.
            </p>
          </div>
          <div className="header-map-wrapper">
            <div className="padding-small">
              <div className="header-map-title-embed">
                <h4 className="map-title">
                  Select a State
                </h4>
                <div className="html-embed w-embed w-iframe w-script">
                  <ClickableUSAMap onclick={onclickState}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="mobile-button-to-search">
    <div className="container-small">
      <div className="padding-small">
        <div className="mobile-button-to-files-wrapper">
          <a onClick={() => onclickState('')} className="button-moble-go-to-search w-button">Start Search</a>
        </div>
      </div>
    </div>
  </section>
  </div>
  );

  return (

    <div className="container-medium">
      <div className="padding-small">
        <div className="search-results-wrapper">
          <div className="welcometitle">
            <b>Publicly Available Hospital Price Transparency Files</b>
          </div>
          <div className="welcometext">
          PatientRightsAdvocate.org aggregated publicly available price transparency 
          data from 6,000 U.S. hospitals to empower all Americans with actual, upfront hospital prices.
          We are working on behalf of patients, employers, unions, and workers in the fight for systemwide
          healthcare price transparency to reduce healthcare costs through a functional, competitive market.        
          </div>
          <ClickableUSAMap onclick={onclickState}/>
        </div>
      </div>
    </div>

  );
};



