import React, { useRef, useEffect, useState, useContext } from "react";
// import {useAsync } from "react-async";
import { useAsync, useAsyncCallback } from "react-async-hook";

// import "./styles.css";

import { Header } from '../../branding/Header';
import { Footer } from '../../branding/Footer';

import { AppContext } from '../../context/AppContext';
import { IContextInterface } from '../../../@types/context';

import ServerService from '../../../services/server.service';

const serverService = new ServerService();

interface FacilitySearchSettings {
  sessionid: string;
  requestid: string;
  error?: string;
};

interface FacilitySearchProps {
  tableid: string;
}

function FacilitySearch(props: FacilitySearchProps) {
  const emptySettings = {
    sessionid: '',
    requestid: '',
    error: ''
  }
  const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);

  const { sessionid, search, matches, searchState, listview, mapview, welcome, welcomeState, saveSearch, saveMatches, saveView } = React.useContext(AppContext) as IContextInterface;

  const states = [
    ['All','State'],
    ['Alabama','AL'],
    ['Alaska','AK'],
    ['Arizona','AZ'],
    ['Arkansas','AR'],
    ['American Samoa','AS'],
    ['California','CA'],
    ['Colorado','CO'],
    ['Connecticut','CT'],
    ['Delaware','DE'],
    ['District of Columbia','DC'],
    ['Florida','FL'],
    ['Georgia','GA'],
    ['Guam','GU'],
    ['Hawaii','HI'],
    ['Idaho','ID'],
    ['Illinois','IL'],
    ['Indiana','IN'],
    ['Iowa','IA'],
    ['Kansas','KS'],
    ['Kentucky','KY'],
    ['Louisiana','LA'],
    ['Maine','ME'],
    ['Maryland','MD'],
    ['Massachusetts','MA'],
    ['Michigan','MI'],
    ['Minnesota','MN'],
    ['Mississippi','MS'],
    ['Missouri','MO'],
    ['Montana','MT'],
    ['Nebraska','NE'],
    ['Nevada','NV'],
    ['New Hampshire','NH'],
    ['New Jersey','NJ'],
    ['New Mexico','NM'],
    ['New York','NY'],
    ['North Carolina','NC'],
    ['North Dakota','ND'],
    ['Northern Mariana Islands','MP'],
    ['Ohio','OH'],
    ['Oklahoma','OK'],
    ['Oregon','OR'],
    ['Pennsylvania','PA'],
    ['Puerto Rico','PR'],
    ['Rhode Island','RI'],
    ['South Carolina','SC'],
    ['South Dakota','SD'],
    ['Tennessee','TN'],
    ['Texas','TX'],
    ['Utah','UT'],
    ['Vermont','VT'],
    ['Virginia','VA'],
    ['Virgin Islands','VI'],
    ['Washington','WA'],
    ['West Virginia','WV'],
    ['Wisconsin','WI'],
    ['Wyoming','WY']
  ];

  // Sort state by abbreviation, except for the first entry
  const sortedstates = states.slice(1);
  sortedstates.sort((a,b) => a[1].localeCompare(b[1]));
  sortedstates.unshift(states[0]);


  function clearSettings()
  {
    setCurrentSettings(emptySettings);
  }

  function setSearch(newsearch: string)
  {
    // console.log('setSearch', search, searchState);
    saveSearch(newsearch, undefined);
  }

  function setSearchState(newsearchState: string)
  {
    //console.log('setSearchState', currentSettings, search, searchState);
    saveSearch(undefined, newsearchState);
  }

  function setListview(state: boolean)
  {
    saveView(state, !state, false, search, searchState);
  }

  function setMapview(state: boolean)
  {
    console.log('setMapview', state);
    saveView(!state, state, false, search, searchState);
  }

  function setError(error: string)
  {
    const settings = {...currentSettings};
    settings.error = error;
    setCurrentSettings(settings);
  }
  
  
  // Allow the Editor to call the grid for current grid values
  //const gridRef = useRef<GridObject>(null);
  //const tableformRef = useRef<TableFormObject>(null);

  // Call when ready to retrieve results
  const retrieve = async (newsearch: string, newsearchState: string) => {
    //console.log(retrieve, newsearch, newsearchState);
    // The value 'State' is shown instead of a blank value
    let querystate = newsearchState;
    if (querystate === 'State') {
      querystate = '';
    }
    const data = await serverService.getResponse(`facility/search?search=${search}&searchstate=${querystate}`);
    // console.log('data', data);
    if (!('matches' in data)) {
      data['matches'] = [];
    }
    saveMatches(data['matches'], data['error'], newsearch, newsearchState);
  }

  const runsearch = async () => {
    // console.log('runsearch', search, searchState);
    await retrieve(search, searchState);
  }

  const onSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // console.log('onSave', currentSettings);
  
    // Fetch a new record
    await runsearch();
  };

  const onCancel = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log('onCancel', currentSettings);
  
    //await cancelrecord(currentSettings.tableid);
    //TODO Submit results
  };

  const updateInputValue = async (evt: any) => {
    evt.preventDefault();
    const val = evt.target.value;
    setSearch(val);
    //console.log('updateInputValue', evt.target.value);
  }

  const saveclick = useAsyncCallback(onSave);
  const onupdateclick = useAsyncCallback(updateInputValue);


  // This conditional runs the initial search after the state map is clicked
  if (search == '' && searchState != '' && searchState != 'State' && matches.length == 0) {
    // runsearch is async so it will finish later
    runsearch();
  }


  function ShowMapview() {
    return (
      <div id="w-node-b60bfa4d-5437-36b4-276a-9b44a953e336-d5517b60" className="view-type-tab-wrapper">
        <img src="images/ICON_List-View.svg" loading="lazy" alt="List View Icon" className="icon-tab mobile-icon-tab-left"/>
        <div className="view-label">List View</div>
        <img src="images/ICON_Map-View_Deselected_Lightest-Gray.svg" loading="lazy" alt="Map View Icon" className="icon-tab tab-deselected" onClick={() => setMapview(true)}/>
        <div className="view-label tab-deselected" onClick={() => setMapview(true)}>Map View</div>
    </div>
    );
  }
  
  function ShowListview() {
    return (
      <div id="w-node-b60bfa4d-5437-36b4-276a-9b44a953e336-d5517b61" className="view-type-tab-wrapper">
        <img src="images/ICON_List-View-deselected.svg" loading="lazy" alt="List View Icon" className="icon-tab tab-deselected" onClick={() => setListview(true)}/>
        <div className="view-label tab-deselected"  onClick={() => setListview(true)}>List View</div>
        <img src="images/ICON_Map-View.svg" loading="lazy" alt="Map View Icon" className="icon-tab mobile-icon-tab-left" />
        <div className="view-label">Map View</div>
    </div>
    );
  }

  function Switchview() {
    if (listview) {
      return <ShowMapview/>
    }
    else {
      return <ShowListview/>
    }
  }

  const stateChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === searchState) {
      return;
    }
    //if (value === currentSettings.searchState) {
    //  return;
    //}

    // Validate it is correct
    const validate = states.find(x => x[1] === value);
    if (!validate) {
      return;
    }

    //const search = currentSettings.search;
    setSearchState(value);
    await retrieve(search, value);
  };
  const onstatechange = useAsyncCallback(stateChange);

  function ShowStates() {
    //           <option selected>All</option>
    let key: number = 0;

    // console.log('ShowStates', searchState)
    return (
        <select style={{height: '50px'}} className="state-abbreviation w-dropdown-link" name="states" value={searchState} onChange={onstatechange.execute}>
          <>
          {sortedstates.map((state) => (
            <option key={key++} value={state[1]}>{state[1]}</option>
          ))}
          </>
        </select>
    );
  }

  if (!sessionid || sessionid.length === 0) {
    return (
      null
    );
  }

  if (welcome) {
    return (
      null
    );
  }

    return (
      <div>
        <main className="main-wrapper">
        <section className="section-title-publicly-availaible-ptf">
      <div className="container-medium">
        <div className="padding-small heading-title-top-padding">
          <div className="page-title-wrapper">
            <h2 className="heading heading-style-h2 mobile-header-title">Publicly Available Hospital Price Transparency Files</h2>
          </div>
        </div>
      </div>
    </section>
    <section className="section-back-button-opening-map">
      <div className="container-medium">
        <div className="padding-small padding-back-to-starting-map">
          <div className="back-button-opening-map-wrapper">
            <a href="/" className="back-to-opening-map-link-block bottom-padding-back-to-map-link w-inline-block"><img src="/images/ICON_back.svg" loading="lazy" alt="back button" className="icon_back"/>
              <div className="label-back-to-map">starting map</div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="section-mobile-back-button-and-directive">
      <div className="container-medium">
        <div className="padding-small padding-top-mobile-back-button">
          <div className="back-to-intro-select-state-wrapper">
            <div className="back-button-mobile-to-intro">
              <a href="/" className="back-to-intro-link w-inline-block"><img src="/images/ICON_back.svg" loading="lazy" alt="back button" className="icon_back"/>
                <div className="label-back-to-intro">Intro</div>
              </a>
            </div>
            <div className="select-a-state-to-begin-title">Select a State to Begin</div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-search-field-viewtabs">
        <div className="container-medium">
          <div className="padding-small">
            <div className="search-viewtabs-wrapper">
              <div id="w-node-d8765c65-11ad-846f-f1c1-142c65ab61e5-d5517b60" className="search-primary-state-wrapper">
                <form action="/search" className="search w-form"><input type="search" className="search-input w-input" maxLength={256} name="query" placeholder="Search Hospitals, City, zip" id="search" value={search} onChange={onupdateclick.execute}/>
                  <div data-hover="false" data-delay="0" className="state-dropdown w-dropdown">
                    <ShowStates />
                  </div><button type="submit" value="" className="search-button w-button" onClick={saveclick.execute}/>
                </form>
              </div>
              <Switchview />
            </div>
          </div>
        </div>
      </section>
      </main>
      </div>

    );
}

export default FacilitySearch;