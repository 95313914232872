// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, BrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import AppProvider from './components/context/AppContext';
import Root from './routes/root';
import ErrorPage from './routes/errorpage';
import MissingPage from './routes/missingpage';
import Details, {loader as facilityLoader} from './routes/details';
import Map, {loader as mapLoader} from './routes/map';

import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/details/:facility',
    element: <Details />,
      loader: facilityLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: '/map/:facility',
    element: <Map />,
    loader: mapLoader,
    errorElement: <ErrorPage />,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
